import michael from './michael.jpg'
import taryn from './taryn.jpg'
import tracy from './tracy.jpg'
import brandon from './brandon.jpg'
import ryan from './ryan.jpg'
import jason from './jason.jpg'
import kat from './kat.jpeg'
import dev from './dev.png'

export const organizerPhotos = {
  brandon,
  dev,
  jason,
  kat,
  michael,
  ryan,
  taryn,
  tracy,
}
